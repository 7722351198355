import { LanguageV2 } from '@features/content/languages';
import { VocabularyReviewListItem } from '@features/content/vocabularyReview';

import { LexicalItemResultCard } from './SearchResultItemCard';

type LexicalItemSearchResultItemProps = {
  content: VocabularyReviewListItem;
  language: LanguageV2;
};

export const LexicalItemSearchResultItem = ({ content, language }: LexicalItemSearchResultItemProps) => {
  return (
    <>
      <LexicalItemResultCard content={content} language={language} />
    </>
  );
};
