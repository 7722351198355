import { useCallback } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { LoadedTrueFalseExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { TrueFalseAnswerModes, type TrueFalseAnswerModesType, TrueFalseStatement } from './TrueFalseStatement';
import { WritableInputText } from '@components/WritableInputText';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { Explanation, Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { PreviewModal } from '@features/content/preview';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { constants as contentConstants, findLocalizationInSearchedLanguage } from '@features/content';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { AudioUpload, ImageUpload } from '@components/MediaUpload';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { ExperimentSelector } from '@features/experiment';
import { Sizes } from '@common/enums/Sizes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { getResources } from '@helpers/getResourcesHelper';
import { TrueFalseExerciseActionsCreator } from '@actionCreators/TrueFalseExerciseActionsCreator';
import TrueFalseExerciseInterface from './interfaces/TrueFalseExerciseInterface';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { DisplayMediaSelector, Instructions, removeMediaProcessingValidationError } from '@features/content/exercises';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

const TrueFalseExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedTrueFalseExerciseInterface>) => {
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as TrueFalseExerciseInterface;

  const audioLocalizations = loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableAudioLocalizations = audioLocalizations
    ? audioLocalizations.filter((audioLocalization) =>
        availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
      )
    : [];

  // Image only has EN localization
  const imageLocalization = loadedExercise?.content?.mainBundle?.image?.imageLocalizations?.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );

  const { isEditAvailable } = useIsEditAvailable();

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle');
  const imageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.image');

  if (imageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const mainContentErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.phrase');
  const mainContentTextErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'mainBundle.phrase' && error.message.includes('text'),
  );
  const mainContentAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' && error.message.toLowerCase().includes('audio'),
  );

  if (mainContentAudioErrors.length) {
    let updatedErrors = [...errors];

    availableAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const statementAndAnswersErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'statement' || error.field === 'answer',
  );
  const statementErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'statement');
  const answerErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'answer');

  const isMainContentChangeBlocked =
    (loadedExercise.content?.mainBundle?.phrase?.mappings?.count > 1 ||
      loadedExercise.content?.mainBundle?.phrase?.mappings?.length > 1) &&
    !loadedExercise.content?.mainBundle?.phrase?.isReusingConfirmed;

  const isMainBundleChangeBlocked =
    loadedExercise.content.mainBundle?.mappings?.count &&
    loadedExercise.content.mainBundle?.mappings?.count > 1 &&
    !loadedExercise.content.mainBundle?.isReusingConfirmed;

  const requestOrCancelAudioPayload = {
    bundleName: 'mainBundle',
    field: 'phrase',
    language: courseLearningLanguage,
    type: ContentTypes.exercise,
  };

  const setReusedData = (id: string, field: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        'mainBundle',
        isBundle,
      ),
    );
  };

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.exercise,
          field: 'phrase',
          language: courseLearningLanguage,
          bundleName: 'mainBundle',
        }),
      );
    },
    [dispatch, courseLearningLanguage],
  );

  return (
    <div className="exercise-true-false">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>True or False</Title>
        <HelpDisplayer type="guideline" id="guideline-true-false-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-true-false__main-wrapper">
        <div className="exercise-true-false__wrapper">
          <div className="exercise-true-false__container">
            <h1 className="exercise-true-false__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(TrueFalseExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </div>

        <DisplayMediaSelector
          isImageEnabled={loadedExercise.content.mainImageEnabled}
          isAudioEnabled={loadedExercise.content.mainAudioEnabled}
          onChange={({ audioEnabled, imageEnabled }) => {
            dispatch(ExerciseCommonActions.setAudioEnabled(audioEnabled));
            dispatch(ExerciseCommonActions.setImageEnabled(imageEnabled));
          }}
        />

        <ResourceBundleWrapper
          bundle={loadedExercise.content.mainBundle}
          bundleName={'mainBundle'}
          errors={mainBundleErrors}
          resources={getResources(loadedExercise, courseLearningLanguage)}
        >
          <>
            <div className="exercise-true-false__wrapper">
              <ContentToggler
                text="Image"
                open={!!loadedExercise.content.mainBundle.image?._id}
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true);
                  } else {
                    dispatch(TrueFalseExerciseActionsCreator.removeImage());
                  }
                }}
                isChangeBlocked={!!isMainBundleChangeBlocked}
                errors={imageErrors}
              >
                <ImageUpload
                  width={ImageUploadDimensionDescriptors.trueFalse.image.width}
                  height={ImageUploadDimensionDescriptors.trueFalse.image.height}
                  size={Sizes.fullscreen}
                  mode={ImageUploadModes.normal}
                  onChange={(file: File, progressHandler: Function) => {
                    if (file !== null && loadedExercise.content.id !== undefined) {
                      dispatch(TrueFalseExerciseActionsCreator.setImage(loadedExercise, file, progressHandler));
                    }
                  }}
                  onRemove={() => {
                    dispatch(TrueFalseExerciseActionsCreator.removeImage());
                  }}
                  imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                    loadedExercise,
                    'image',
                    undefined,
                    'mainBundle',
                  )}
                  onProcessingFinished={(url: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setValueAfterProcessing({
                        url,
                        mediaType: 'image',
                        type: ContentTypes.exercise,
                        field: 'image',
                        language: 'EN',
                        bundleName: 'mainBundle',
                      }),
                    );
                  }}
                  previewMode={false}
                  isForExercise
                  errors={imageErrors}
                  isChangeBlocked={!!isMainBundleChangeBlocked}
                  onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true)}
                />
              </ContentToggler>
            </div>
            <div className="exercise-true-false__wrapper">
              <ContentToggler
                text="Audio"
                open={
                  !!findLocalizationInSearchedLanguage(
                    loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations,
                    courseLearningLanguage,
                  )?.value
                }
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
                  } else if (isMainContentChangeBlocked) {
                    setReusedData(
                      loadedExercise.content.mainBundle?.phrase?._id ||
                        loadedExercise.content.mainBundle?.phrase?.id ||
                        '',
                      'phrase',
                      false,
                    );
                  } else {
                    dispatch(
                      TrueFalseExerciseActionsCreator.removeAudio({
                        contentId:
                          loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id,
                        language: courseLearningLanguage,
                      }),
                    );
                  }
                }}
                isChangeBlocked={isMainBundleChangeBlocked || isMainContentChangeBlocked}
                errors={
                  mainContentTextErrors.length || mainContentAudioErrors.length
                    ? mainContentAudioErrors
                    : mainContentErrors
                }
              >
                <AudioUpload
                  audioData={ImageUploadUtils.getAudioForFileUpload(
                    loadedExercise,
                    'phrase',
                    courseLearningLanguage,
                    'mainBundle',
                  )}
                  onAudioRequestSuccess={(audioRequestId: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                        ...requestOrCancelAudioPayload,
                        audioRequestData: {
                          id: audioRequestId,
                          status: 'new',
                        },
                      }),
                    );
                  }}
                  onCancelAudioRequestSuccess={() => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
                    );
                  }}
                  onProcessingFinished={onProcessingFinished}
                  onChange={(uploadedSound, progressHandler) => {
                    dispatch(
                      AudioUploadActionsCreator.uploadSound(
                        loadedExercise.content.id,
                        ExerciseTypes.trueFalse,
                        courseLearningLanguage,
                        loadedExercise.content?.mainBundle?.phrase?._id,
                        findLocalizationInSearchedLanguage(
                          loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations as any,
                          courseLearningLanguage,
                        ),
                        uploadedSound,
                        'phrase',
                        undefined,
                        undefined,
                        true,
                        'mainBundle',
                        progressHandler,
                      ),
                    );
                  }}
                  onRemove={() => {
                    dispatch(
                      TrueFalseExerciseActionsCreator.removeAudio({
                        contentId:
                          loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id,
                        language: courseLearningLanguage,
                      }),
                    );
                  }}
                  onChangeInstant={() => {
                    if (isMainBundleChangeBlocked) {
                      setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
                    } else {
                      setReusedData(
                        loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id ||
                          '',
                        'phrase',
                        false,
                      );
                    }
                  }}
                  isChangeBlocked={isMainBundleChangeBlocked || isMainContentChangeBlocked}
                  fullScreen
                  errors={
                    mainContentTextErrors.length || mainContentAudioErrors.length
                      ? mainContentAudioErrors
                      : mainContentErrors
                  }
                  currentLanguage={courseLearningLanguage}
                  fieldName={'phrase'}
                />
              </ContentToggler>
            </div>
            <div className="exercise-true-false__wrapper">
              <ContentToggler
                text="Example text"
                open={!!loadedExercise?.content?.mainBundle?.phrase?._id}
                errors={
                  mainContentTextErrors.length || mainContentAudioErrors.length
                    ? mainContentTextErrors
                    : mainContentErrors
                }
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
                  } else {
                    dispatch(TrueFalseExerciseActionsCreator.removeMainContent());
                    setFieldValue(
                      `mainBundle_phrase`,
                      values.mainBundle_phrase?.map((item: any) => ({ ...item, value: '' })),
                    );
                    setFieldValue(`mainBundle_phraseChanged`, true);
                  }
                }}
                isChangeBlocked={!!isMainBundleChangeBlocked}
              >
                <div className="exercise-true-false__examples-wrapper">
                  <TranslationsTipV2
                    bundleName={'mainBundle'}
                    visitedBranch={'phrase'}
                    errors={
                      mainContentTextErrors.length || mainContentAudioErrors.length
                        ? mainContentTextErrors
                        : mainContentErrors
                    }
                    showErrorDisplayer={false}
                  >
                    <WritableInputText bold fontSize="24" placeholder="Example text" id="true-false-example-input" />
                  </TranslationsTipV2>
                </div>
              </ContentToggler>
            </div>
          </>
        </ResourceBundleWrapper>
        <div className="exercise-true-false__container">
          <h1 className="exercise-true-false__box-title">True/False statement</h1>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(statementAndAnswersErrors)} />
        </div>

        <TrueFalseStatement
          state={loadedExercise.content.answer}
          language={exerciseParam.exercise?.content.statementLanguage}
          onToggleChange={(isTrue: TrueFalseAnswerModesType) => {
            if (isTrue === TrueFalseAnswerModes.true) {
              dispatch(TrueFalseExerciseActionsCreator.setStatementAs(true));
            } else {
              dispatch(TrueFalseExerciseActionsCreator.setStatementAs(false));
            }
          }}
          inputErrors={statementErrors}
          answerErrors={answerErrors}
        />
        <Explanation />
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </div>
    </div>
  );
};

export default TrueFalseExercise;
