import { FormikContextType, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { MediaDataInterface } from '@common/interfaces/contentTypes/MediaDataInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer/ValidationErrorDisplayer';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { ImageUpload } from '@components/MediaUpload';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { AccessWarning } from '@components/Warning';
import { WritableInputText } from '@components/WritableInputText';
import { useIsEditAvailable } from '@features/content/courses';
import { CefrSelector } from '@features/content/levels';
import { ImageDataType } from '@features/content/media';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectRoleplayScenarioContent } from '@selectors/roleplaySelectors';
import helpersService from '@services/HelpersService';

import { RoleplayService } from '../RoleplayService';
/** @TODO Use common @features/content/translations/TranslationsTip */
import { TranslationsTipWrapper } from '../TranslationsTip';
import type { RoleplayScenarioNumberFieldNames, RoleplayScenarioStringFieldNames } from '../types';
import { useCurrentRoleplayContent } from '../useCurrentRoleplayContent';
import {
  FieldGroupHeader,
  FieldLabel,
  RangeInput,
  ScenarioDataFieldsWrapper,
  ScenarioTitleWrapper,
  StyledCol,
  StyledRow,
  StyledTranslationsTipWrapper,
} from './styles';
import { AccentSelector, GenderSelector } from './Voice';
import { MESSAGE_LIMIT } from './constants';

/** @TODO Move this to @features/content/media to be reused */
const mapImageDataToMediaData = (imageData: ImageDataType | null): MediaDataInterface | undefined => {
  if (imageData) {
    const { id, localizationId, processed, value } = imageData;

    return {
      contentId: localizationId,
      mediaId: id,
      mediaValue: value,
      processed,
    };
  }
};

const isInRange = (value: number) => !isNaN(value) && value <= MESSAGE_LIMIT.MAX && value >= MESSAGE_LIMIT.MIN;

export const RoleplayScenarioData = () => {
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const { languageId } = useCurrentRoleplayContent();

  const [accentOptions, setAccentOptions] = useState<string[]>([]);

  const scenarioContent = useAppSelector(selectRoleplayScenarioContent);

  const { errors } = scenarioContent?.validationStatus || { errors: [] };
  const titleValidationErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'title');
  const descriptionValidationErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'description');
  const imageValidationErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'image');
  const goalErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'goal');
  const triggerMessagesErrors = errors.filter((error: ValidationErrorInterface) =>
    error.field.startsWith('triggerMessages'),
  );
  const chatbotScenarioErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'chatbotScenario');
  const chatbotGoalErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'chatbotGoal');
  const chatbotRoleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'chatbotRole');
  const vocabularyErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'vocabulary');
  const grammarErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'grammar');
  const sampleDialogueErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'sampleDialogue');
  const voiceAccentErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'voice.accent');

  const onFieldChange = (
    fieldName: RoleplayScenarioStringFieldNames | RoleplayScenarioNumberFieldNames,
    value: string | string[] | number | null,
  ) => {
    setFieldValue(fieldName, value);
    dispatch(RoleplayActionCreators.updateScenarioField(fieldName, value));
  };

  const validateMessageLimit = (value: number) => {
    let _value = value;
    const fieldName = 'messageLimit';

    if (value > MESSAGE_LIMIT.MAX) _value = MESSAGE_LIMIT.MAX;
    if (value < MESSAGE_LIMIT.MIN) _value = MESSAGE_LIMIT.MIN;

    setFieldValue(fieldName, _value);
  };

  useEffect(() => {
    // @TODO Add cache
    if (!accentOptions.length) {
      RoleplayService.getVoiceAccents(languageId).then((result) => {
        setAccentOptions(result.data.accents);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId]);

  return (
    <div className="course-composer">
      <div className="course-edition__first-top-element">
        <div className="course-edition__publish-status-and-other-places-container">
          <PublishingStatus ready={scenarioContent.ready} changeStatus={scenarioContent.changeStatus} />
        </div>
        <StyledRow>
          <StyledCol>
            <div id={`scenario-${scenarioContent.id}`}>
              <IDDisplayer id={scenarioContent.id} mode="normal" />
              <ScenarioTitleWrapper>
                <StyledTranslationsTipWrapper
                  content={scenarioContent.title}
                  defaultContextForTranslators="Roleplay Scenario"
                  fieldName="title"
                  errors={titleValidationErrors}
                  type={ContentTypes.roleplayScenario}
                >
                  <WritableInputText
                    bold
                    fontSize="30"
                    id="scenario-title-input"
                    placeholder="Scenario"
                    withGreyColor
                    withoutBorder
                  />
                </StyledTranslationsTipWrapper>
              </ScenarioTitleWrapper>
              <TranslationsTipWrapper
                content={scenarioContent.description}
                defaultContextForTranslators="Roleplay Scenario"
                fieldName="description"
                errors={descriptionValidationErrors}
                type={ContentTypes.roleplayScenario}
              >
                <WritableInputText
                  withGreyColor
                  withoutBorder
                  placeholder={'Scenario description'}
                  id="scenario-description-input"
                />
              </TranslationsTipWrapper>
            </div>
          </StyledCol>
          <StyledCol sm={4} className="course-edition__file-upload">
            <ImageUpload
              fieldName="image"
              errors={imageValidationErrors}
              imageData={mapImageDataToMediaData(scenarioContent.image)}
              mode={ImageUploadModes.normal}
              withHelp={false}
              onProcessingFinished={(url: string) => {
                dispatch(
                  RoleplayActionCreators.setMediaValueAfterProcessing(ContentTypes.roleplayScenario, 'image', url),
                );
              }}
              previewMode={false}
              onChange={(file, progressHandler) => {
                dispatch(
                  RoleplayActionCreators.uploadImageToScenario({
                    scenarioId: scenarioContent.id,
                    contentIdBeingUpdated: scenarioContent.image?.id,
                    file,
                    progressHandler,
                  }),
                );
              }}
              onRemove={() => {
                dispatch(RoleplayActionCreators.removeImageFromScenario(scenarioContent.id));
              }}
            />
          </StyledCol>
        </StyledRow>

        {!isEditAvailable && <AccessWarning />}

        <ScenarioDataFieldsWrapper>
          <StyledRow>
            <StyledCol>
              <FieldLabel>Goal</FieldLabel>
              <TranslationsTipWrapper
                content={scenarioContent.goal}
                fieldName="goal"
                errors={goalErrors}
                type={ContentTypes.roleplayScenario}
              >
                <WritableInputText fontSize="16" id="scenario-goal" />
              </TranslationsTipWrapper>
            </StyledCol>
          </StyledRow>

          <StyledRow>
            <StyledCol>
              <FieldLabel>CEFR</FieldLabel>
              <CefrSelector
                showFullName
                value={values.cefr}
                onChange={(option) => onFieldChange('cefr', option?.value as string)}
              />
            </StyledCol>

            <StyledCol>
              <FieldLabel>Message limit</FieldLabel>
              <RangeInput
                min={MESSAGE_LIMIT.MIN}
                max={MESSAGE_LIMIT.MAX}
                type="number"
                value={values.messageLimit}
                onBlur={() => {
                  setFieldValue('messageLimit', values.messageLimit);
                  dispatch(RoleplayActionCreators.updateScenarioField('messageLimit', values.messageLimit as number));
                }}
                onChange={(evt) => {
                  const value = parseInt(evt.target.value);
                  isInRange(value) && onFieldChange('messageLimit', value);
                }}
                onKeyDown={(evt) => {
                  const value = parseInt(evt.key);
                  if (!isNaN(value)) {
                    // allow backspace
                    evt.preventDefault();
                    !isNaN(value) && validateMessageLimit(value);
                  }
                }}
              />
            </StyledCol>
          </StyledRow>

          <FieldGroupHeader>Chatbot Info</FieldGroupHeader>

          <StyledRow>
            <StyledCol>
              <FieldLabel>Chatbot trigger message</FieldLabel>
              <TranslationsTipWrapper
                content={scenarioContent.triggerMessage1}
                fieldName="triggerMessage1"
                errors={triggerMessagesErrors}
                type={ContentTypes.roleplayScenario}
              >
                <WritableInputText fontSize="16" id="scenario-chatbot-trigger-message1" />
              </TranslationsTipWrapper>
            </StyledCol>
          </StyledRow>

          <StyledRow>
            <StyledCol>
              <FieldLabel>Chatbot scenario</FieldLabel>
              <WritableInputText
                fontSize="16"
                id="scenario-chatbot-scenario"
                mode="area"
                value={values.chatbotScenario}
                onChange={(value) => onFieldChange('chatbotScenario', value)}
              />
            </StyledCol>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(chatbotScenarioErrors)} />
          </StyledRow>

          <StyledRow>
            <StyledCol>
              <FieldLabel>Chatbot goal</FieldLabel>
              <WritableInputText
                fontSize="16"
                id="scenario-chatbot-goal"
                mode="area"
                value={values.chatbotGoal}
                onChange={(value) => onFieldChange('chatbotGoal', value)}
              />
              <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(chatbotGoalErrors)} />
            </StyledCol>

            <StyledCol>
              <FieldLabel>Chatbot role</FieldLabel>
              <WritableInputText
                fontSize="16"
                id="scenario-chatbot-role"
                mode="area"
                value={values.chatbotRole}
                onChange={(value) => onFieldChange('chatbotRole', value)}
              />
            </StyledCol>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(chatbotRoleErrors)} />
          </StyledRow>

          <StyledRow>
            <StyledCol>
              <FieldLabel>Vocabulary</FieldLabel>
              <WritableInputText
                fontSize="16"
                id="scenario-vocabulary"
                mode="area"
                value={values.vocabulary}
                onChange={(value) => onFieldChange('vocabulary', value)}
              />
            </StyledCol>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(vocabularyErrors)} />
          </StyledRow>

          <StyledRow>
            <StyledCol>
              <FieldLabel>Grammar</FieldLabel>
              <WritableInputText
                fontSize="16"
                id="scenario-grammar"
                mode="area"
                value={values.grammar}
                onChange={(value) => onFieldChange('grammar', value)}
              />
              <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(grammarErrors)} />
            </StyledCol>
          </StyledRow>

          <StyledRow>
            <StyledCol>
              <FieldLabel>Sample dialogue</FieldLabel>
              <WritableInputText
                fontSize="16"
                id="scenario-sample-dialogue"
                mode="area"
                value={values.sampleDialogue}
                onChange={(value) => onFieldChange('sampleDialogue', value)}
              />
              <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(sampleDialogueErrors)} />
            </StyledCol>
          </StyledRow>

          <FieldGroupHeader>Voice</FieldGroupHeader>

          <StyledRow>
            <StyledCol>
              <FieldLabel>Gender</FieldLabel>
              <GenderSelector
                value={values.voiceGender}
                onChange={(option) => onFieldChange('voiceGender', option?.value as string)}
              />
            </StyledCol>

            <StyledCol>
              <FieldLabel>Accent</FieldLabel>
              <AccentSelector
                options={accentOptions}
                value={values.voiceAccent ?? accentOptions[0]}
                onChange={(option) => onFieldChange('voiceAccent', option?.value as string)}
              />
              <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(voiceAccentErrors)} />
            </StyledCol>
          </StyledRow>
        </ScenarioDataFieldsWrapper>
      </div>
    </div>
  );
};
