import { type FormikContextType, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { LoadingStageType } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import type { LanguageV2 } from '@features/content/languages';
import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsSaveButtonEnabled } from '@selectors/CoursesSelectors';
import { selectRoleplay, selectRoleplayCategory, selectRoleplayScenario } from '@selectors/roleplaySelectors';

import type {
  RoleplayCategoryFormikValues,
  RoleplayCategoryListItemType,
  RoleplayListItemType,
  RoleplayScenarioFormikValues,
} from './types';

export const useCurrentRoleplayContent = () => {
  const dispatch = useAppDispatch();
  const formikContext: FormikContextType<RoleplayCategoryFormikValues | RoleplayScenarioFormikValues> =
    useFormikContext();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);

  const { languageId, categoryId, scenarioId } = useParams<{
    languageId: LanguageV2;
    categoryId: DBId;
    scenarioId: DBId;
  }>();

  const roleplay = useAppSelector(selectRoleplay);
  const roleplayCategory = useAppSelector(selectRoleplayCategory);
  const roleplayScenario = useAppSelector(selectRoleplayScenario);

  const { interfaceLanguages } = roleplay;

  let childrenArray: RoleplayListItemType[] | RoleplayCategoryListItemType = roleplay.categoriesData.categories;
  let isRoleplayLoaded: LoadingStageType = roleplay.loaded;
  let roleplayType: ContentTypesType = ContentTypes.roleplay;
  let roleplayContent = roleplay.content;
  let parentId;
  let onSave = () => {};

  if (languageId && categoryId && scenarioId) {
    roleplayType = ContentTypes.roleplayScenario;
    roleplayContent = roleplayScenario.content;
    isRoleplayLoaded = roleplayScenario.loaded;
    childrenArray = [];
    parentId = categoryId;

    onSave = () => {
      dispatch(
        RoleplayActionCreators.saveScenario({
          values: formikContext?.values as RoleplayScenarioFormikValues,
          languageId,
          roleplayId: roleplay.content.id,
          categoryId,
          scenarioId,
        }),
      );
      formikContext?.resetForm({ values: formikContext?.values });
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    };
  } else if (languageId && categoryId && !scenarioId) {
    roleplayType = ContentTypes.roleplayCategory;
    roleplayContent = roleplayCategory.content;
    isRoleplayLoaded = roleplayCategory.loaded;
    childrenArray = roleplayCategory.scenariosData.scenarios;
    parentId = roleplay.content.id;

    onSave = () => {
      dispatch(
        RoleplayActionCreators.saveCategory({
          values: formikContext?.values,
          languageId,
          roleplayId: roleplay.content.id,
          categoryId,
        }),
      );
      formikContext?.resetForm({ values: formikContext?.values });
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    };
  }

  // Update save button availability on content change
  useEffect(() => {
    const isRoleplayCategoryChanged =
      categoryId && !scenarioId && (roleplayCategory.content.iconChanged || formikContext?.dirty);

    const isRoleplayCategoryNotChanged =
      categoryId && !scenarioId && !(roleplayCategory.content.iconChanged || formikContext?.dirty);

    if (isRoleplayCategoryChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (isRoleplayCategoryNotChanged && isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    }

    const isRoleplayScenarioChanged = scenarioId && formikContext?.dirty;
    const isRoleplayScenarioNotChanged = scenarioId && !formikContext?.dirty;

    if (isRoleplayScenarioChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (isRoleplayScenarioNotChanged && isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    }
  }, [categoryId, dispatch, formikContext, isSaveButtonEnabled, roleplayCategory, scenarioId]);

  return {
    childrenArray,
    interfaceLanguages,
    isRoleplayLoaded,
    languageId,
    parentId,
    roleplayContent,
    roleplayType,
    onSave,
  };
};
