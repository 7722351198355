import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { RoleplayActions } from '@actions/RoleplayActions';
import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { MediaTypesType } from '@common/enums/MediaTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { ChangeStatusInterface } from '@common/interfaces/contentTypes/ChangeStatusInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { RoleplaySliceType } from '@common/interfaces/slices/RoleplaySliceType';
import { ValidationInterface } from '@common/interfaces/validation/ValidationInterface';
import { DBId } from '@common/types/DBId';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ALL_LANGUAGES_V2, type LanguageV2 } from '@features/content/languages';
import { ImageDataType } from '@features/content/media';
import type {
  RoleplayCategoryContentType,
  RoleplayContentType,
  RoleplayListItemType,
  RoleplayCategoryEditableFieldNames,
  RoleplayCategoryListItemType,
  RoleplayCategoryFormikValues,
  RoleplayScenarioContentType,
  RoleplayScenarioStringFieldNames,
  RoleplayScenarioNumberFieldNames,
  RoleplayScenarioFormikValues,
} from '@features/content/roleplay';
import {
  roleplayInitialContent,
  roleplayCategoryInitialContent,
  roleplayScenarioInitialContent,
} from '@redux/initialStates/roleplayInitialState';

export const RoleplayReducers = {
  // Roleplay
  [RoleplayActions.ROLEPLAY_LOADING]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplay.loaded = LoadingStage.loading;
    });
  },
  [RoleplayActions.ROLEPLAY_LOADED]: (state: RoleplaySliceType, { payload }: PayloadAction<RoleplayContentType>) => {
    return produce(state, (draft) => {
      draft.roleplay.loaded = LoadingStage.loaded;
      draft.roleplay.content = { ...payload, type: ContentTypes.roleplay };
      draft.roleplay.interfaceLanguages = ALL_LANGUAGES_V2 as unknown as LanguageV2[];
    });
  },
  [RoleplayActions.SET_INITIAL_ROLEPLAY]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplay.loaded = LoadingStage.notLoaded;
      draft.roleplay.content = roleplayInitialContent;
      draft.roleplay.categoriesData.loaded = LoadingStage.notLoaded;
      draft.roleplay.categoriesData.categories = [];
    });
  },
  [RoleplayActions.ROLEPLAY_CATEGORIES_LOADING]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplay.categoriesData.loaded = LoadingStage.loading;
    });
  },
  [RoleplayActions.ROLEPLAY_CATEGORIES_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayListItemType[]>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplay.categoriesData.loaded = LoadingStage.loaded;
      draft.roleplay.categoriesData.categories = payload.map((category) => ({
        ...category,
        type: ContentTypes.roleplayCategory,
      }));
    });
  },
  [RoleplayActions.SET_STRING]: (
    state: RoleplaySliceType,
    {
      payload: { newString: notFilledNewString, contentType, fieldName, isReuse = false },
    }: PayloadAction<{
      newString: TranslationsPanelContentInterface;
      contentType: ContentTypesType;
      fieldName: RoleplayCategoryEditableFieldNames;
      isReuse?: boolean;
    }>,
  ): RoleplaySliceType => {
    const newString = {
      ...notFilledNewString,
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['textLocalizations'],
        notFilledNewString.textLocalizations,
      ),
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['audioLocalizations'],
        notFilledNewString.audioLocalizations,
      ),
      isReused: isReuse,
    };

    switch (contentType) {
      case ContentTypes.roleplayCategory: {
        return produce(state, (draft) => {
          draft.roleplayCategory.content[fieldName] = newString;
          draft.roleplayCategory.content[`${fieldName}Changed`] = true;
        });
      }
      case ContentTypes.roleplayScenario: {
        return produce(state, (draft) => {
          draft.roleplayScenario.content[fieldName] = newString;
          draft.roleplayScenario.content[`${fieldName}Changed`] = true;
        });
      }
      default: {
        console.log('Unknown type');
        return state;
      }
    }
  },
  [RoleplayActions.SET_VALIDATION_RESULT]: (
    state: RoleplaySliceType,
    {
      payload,
    }: PayloadAction<{
      type: ContentTypesType;
      data: { validationStatus: ValidationInterface; changeStatus: ChangeStatusInterface };
    }>,
  ) => {
    const { type: contentType, data: validationResult } = payload;

    switch (contentType) {
      case ContentTypes.roleplay: {
        return produce(state, (draft) => {
          draft.roleplay.content.validationStatus = validationResult.validationStatus;
          draft.roleplay.content.changeStatus = validationResult.changeStatus;
        });
      }
      case ContentTypes.roleplayCategory: {
        return produce(state, (draft) => {
          draft.roleplayCategory.content.validationStatus = validationResult.validationStatus;
          draft.roleplayCategory.content.changeStatus = validationResult.changeStatus;
        });
      }
      case ContentTypes.roleplayScenario: {
        return produce(state, (draft) => {
          draft.roleplayScenario.content.validationStatus = validationResult.validationStatus;
          draft.roleplayScenario.content.changeStatus = validationResult.changeStatus;
        });
      }

      default: {
        console.log('Unknown Roleplay type');
      }
    }
  },

  // Roleplay Navigation
  [RoleplayActions.SET_LOADING_PARENT_ID]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<DBId | LanguageV2>,
  ) => {
    return produce(state, (draft) => {
      draft.navigation.loadingParentId = payload;
    });
  },
  [RoleplayActions.UPDATE_NAVIGATION]: (state: RoleplaySliceType, { payload }: PayloadAction<any>) => {
    return produce(state, (draft) => {
      draft.navigation.structure = payload;
    });
  },

  // Roleplay Category
  [RoleplayActions.ROLEPLAY_CATEGORY_LOADING]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.loaded = LoadingStage.loading;
    });
  },
  [RoleplayActions.ROLEPLAY_CATEGORY_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayCategoryContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.loaded = LoadingStage.loaded;
      draft.roleplayCategory.content = {
        ...payload,
        title:
          payload.title?.textLocalizations?.length || payload.title?.audioLocalizations?.length
            ? {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.title?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.title?.audioLocalizations,
                ),
              }
            : {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        type: ContentTypes.roleplayCategory,
      };
    });
  },
  [RoleplayActions.ROLEPLAY_SCENARIOS_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayCategoryListItemType[]>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.scenariosData.loaded = LoadingStage.loaded;
      draft.roleplayCategory.scenariosData.scenarios = payload.map((scenario) => ({
        ...scenario,
        type: ContentTypes.roleplayScenario,
      }));
    });
  },
  [RoleplayActions.SET_INITIAL_ROLEPLAY_CATEGORY]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.loaded = LoadingStage.notLoaded;
      draft.roleplayCategory.content = roleplayCategoryInitialContent;
      draft.roleplayCategory.scenariosData.loaded = LoadingStage.notLoaded;
      draft.roleplayCategory.scenariosData.scenarios = [];
    });
  },
  [RoleplayActions.SET_ROLEPLAY_CATEGORY_TITLE]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<TranslationsPanelContentInterface>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.content.title = {
        ...payload,
        ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
          ['textLocalizations'],
          payload.textLocalizations,
        ),
        ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
          ['audioLocalizations'],
          payload.audioLocalizations,
        ),
      };
    });
  },
  [RoleplayActions.SET_ROLEPLAY_CATEGORY_ICON]: (state: RoleplaySliceType, { payload }: PayloadAction<string>) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.content.icon = payload;
      draft.roleplayCategory.content.iconChanged = true;
    });
  },
  [RoleplayActions.SET_CATEGORY_ALL_VALUES]: (
    state: RoleplaySliceType,
    { payload: { values } }: PayloadAction<{ values: RoleplayCategoryFormikValues }>,
  ): RoleplaySliceType => {
    return produce(state, (draft) => {
      if (values.titleChanged && draft.roleplayCategory.content.title?.textLocalizations) {
        if (draft.roleplayCategory.content.title?.isReused) {
          draft.roleplayCategory.content.title.isReused = false;
        }

        draft.roleplayCategory.content.title.textLocalizations =
          draft.roleplayCategory.content.title.textLocalizations.map((loc) => ({
            ...loc,
            value: values.title.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.title.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }
    });
  },
  [RoleplayActions.SET_CATEGORY_TO_NOT_CHANGED]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.content.iconChanged = false;
      draft.roleplayCategory.content.titleChanged = false;
    });
  },

  // Scenario
  [RoleplayActions.SET_INITIAL_SCENARIO]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayScenario.loaded = LoadingStage.notLoaded;
      draft.roleplayScenario.content = roleplayScenarioInitialContent;
    });
  },
  [RoleplayActions.SCENARIO_LOADING]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayScenario.loaded = LoadingStage.loading;
    });
  },
  [RoleplayActions.SCENARIO_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayScenarioContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplayScenario.loaded = LoadingStage.loaded;

      const content = {
        ...payload,
        // Fields with localization
        title:
          payload.title?.textLocalizations?.length || payload.title?.audioLocalizations?.length
            ? {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.title?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.title?.audioLocalizations,
                ),
              }
            : {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        description:
          payload.description?.textLocalizations?.length || payload.description?.audioLocalizations?.length
            ? {
                ...payload.description,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.description?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.description?.audioLocalizations,
                ),
              }
            : {
                ...payload.description,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        goal:
          payload.goal?.textLocalizations?.length || payload.goal?.audioLocalizations?.length
            ? {
                ...payload.goal,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.goal?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.goal?.audioLocalizations,
                ),
              }
            : {
                ...payload.goal,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        triggerMessage1:
          payload.triggerMessages?.[0]?.textLocalizations?.length ||
          payload.triggerMessages?.[0]?.audioLocalizations?.length
            ? {
                ...payload.triggerMessages[0],
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.triggerMessages[0]?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.triggerMessages[0]?.audioLocalizations,
                ),
              }
            : {
                ...(payload.triggerMessages?.[0] ?? {}),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        // Fields without localization
        chatbotGoal: payload.meta?.chatbotGoal as string,
        chatbotRole: payload.meta?.chatbotRole as string,
        chatbotScenario: payload.meta?.chatbotScenario as string,
        grammar: payload.meta?.grammar as string,
        sampleDialogue: payload.meta?.sampleDialogue as string,
        vocabulary: payload.meta?.vocabulary as string,
        voiceAccent: payload.meta?.voice.accent as string,
        voiceGender: payload.meta?.voice.gender as string,
        type: ContentTypes.roleplayScenario,
      };

      delete content.triggerMessages;

      draft.roleplayScenario.content = content;
    });
  },
  [RoleplayActions.SET_SCENARIO_IMAGE]: (
    state: RoleplaySliceType,
    { payload: { description, id, localizationId, value } }: PayloadAction<ImageDataType>,
  ) => {
    return produce(state, (draft) => {
      if (id === null) {
        draft.roleplayScenario.content.image = null;
      } else {
        draft.roleplayScenario.content.image = {
          description,
          id,
          localizationId,
          value,
          processed: false,
        };
      }
    });
  },
  [RoleplayActions.UPDATE_SCENARIO_FIELD]: (
    state: RoleplaySliceType,
    {
      payload: { fieldName, value },
    }: PayloadAction<{
      fieldName: RoleplayScenarioStringFieldNames | RoleplayScenarioNumberFieldNames;
      value: string | number;
    }>,
  ) => {
    return produce(state, (draft) => {
      if (fieldName === 'messageLimit') {
        draft.roleplayScenario.content.messageLimit = value as number;
      } else {
        draft.roleplayScenario.content[fieldName] = value as string;
      }
      draft.roleplayScenario.content[`${fieldName}Changed`] = true;
    });
  },
  [RoleplayActions.SET_MEDIA_VALUE_AFTER_PROCESSING]: (
    state: RoleplaySliceType,
    {
      payload: { contentType, mediaType, mediaUrl },
    }: PayloadAction<{ contentType: DBId; mediaType: MediaTypesType; mediaUrl: string }>,
  ) => {
    return produce(state, (draft) => {
      if (contentType === ContentTypes.roleplayScenario) {
        (draft.roleplayScenario.content[mediaType as keyof RoleplayScenarioContentType] as ImageDataType).value =
          mediaUrl;
        (draft.roleplayScenario.content[mediaType as keyof RoleplayScenarioContentType] as ImageDataType).processed =
          true;
      }
    });
  },
  [RoleplayActions.SET_SCENARIO_ALL_VALUES]: (
    state: RoleplaySliceType,
    { payload: { values } }: PayloadAction<{ values: RoleplayScenarioFormikValues }>,
  ): RoleplaySliceType => {
    return produce(state, (draft) => {
      if (values.titleChanged && draft.roleplayScenario.content.title?.textLocalizations) {
        if (draft.roleplayScenario.content.title?.isReused) {
          draft.roleplayScenario.content.title.isReused = false;
        }

        draft.roleplayScenario.content.title.textLocalizations =
          draft.roleplayScenario.content.title.textLocalizations.map((loc) => ({
            ...loc,
            value: values.title.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.title.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }

      if (values.descriptionChanged && draft.roleplayScenario.content.description?.textLocalizations) {
        if (draft.roleplayScenario.content.description?.isReused) {
          draft.roleplayScenario.content.description.isReused = false;
        }
        draft.roleplayScenario.content.description.textLocalizations =
          draft.roleplayScenario.content.description?.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.description.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.description.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }

      if (values.goalChanged && draft.roleplayScenario.content.goal?.textLocalizations) {
        draft.roleplayScenario.content.goal.textLocalizations =
          draft.roleplayScenario.content.goal?.textLocalizations.map((loc) => ({
            ...loc,
            value: values.goal.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.goal.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }
    });
  },

  // Translation Panel
  [RoleplayActions.SET_AUDIO]: (
    state: RoleplaySliceType,
    {
      payload: { localization, fieldName, mediaURL, type, contentId },
    }: PayloadAction<{
      localization: LocalizationInterface;
      fieldName: RoleplayCategoryEditableFieldNames;
      mediaURL: string;
      type: ContentTypesType;
      contentId: DBId;
    }>,
  ): RoleplaySliceType => {
    switch (type) {
      case ContentTypes.roleplayCategory:
        return produce(state, (draft) => {
          draft.roleplayCategory.content[`${fieldName}Changed`] = true;
          if (contentId) {
            const fieldToUpdate = draft.roleplayCategory.content[fieldName];
            if (fieldToUpdate) {
              fieldToUpdate.id = contentId;
            }
          }

          let foundLocalization = draft.roleplayCategory.content[fieldName]?.audioLocalizations.find(
            (loc) => loc.language === localization.language,
          );

          if (foundLocalization) {
            foundLocalization = {
              ...foundLocalization,
              value: !mediaURL ? '' : foundLocalization.value,
              fileId: mediaURL || null,
              _id: mediaURL || undefined,
              type: 'audio',
              processed: false,
            };
          }
        });
      /**
       * @TODO case ContentTypes.roleplayScenario:
       */

      default:
        return state;
    }
  },
  [RoleplayActions.REMOVE_AUDIO]: (
    state: RoleplaySliceType,
    {
      payload: { localization, fieldName, type },
    }: PayloadAction<{
      localization: LocalizationInterface;
      fieldName: RoleplayCategoryEditableFieldNames;
      type: ContentTypesType;
    }>,
  ): RoleplaySliceType => {
    switch (type) {
      case ContentTypes.grammarCategory:
        return produce(state, (draft) => {
          draft.roleplayCategory.content[`${fieldName}Changed`] = true;

          draft.roleplayCategory.content[fieldName].audioLocalizations = draft.roleplayCategory.content[
            fieldName
          ].audioLocalizations.map((loc) =>
            loc.language === localization.language
              ? {
                  ...loc,
                  value: '',
                  fileId: null,
                  _id: undefined,
                  processed: false,
                }
              : loc,
          );
        });
      /**
       * @TODO  case ContentTypes.roleplayScenario:
       *  */
      default:
        return state;
    }
  },
};
