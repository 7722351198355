import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useCallback, useEffect, useState } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { FormikValueInterface, FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { LoadedFillGapExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { AudioUpload, ImageUpload, VideoUpload } from '@components/MediaUpload';
import { VideoUploadActionsCreator } from '@actionCreators/VideoUploadActionsCreator';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { WritableInputText } from '@components/WritableInputText';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import KTagsCreatorHelpers from '@components/Exercises/Common/KTagsCreator/KTagsCreatorHelpers';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { PreviewModal } from '@features/content/preview';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { Explanation, Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import FillgapTool from './FillgapTool/FillgapTool';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { getResources } from '@helpers/getResourcesHelper';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { Sizes } from '@common/enums/Sizes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { ExperimentSelector } from '@features/experiment';
import { courseSlice } from '@redux/slices/courseSlice';
import { constants as contentConstants } from '@features/content';
import { DEFAULT_LANGUAGE_V2, LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { Instructions, removeMediaProcessingValidationError } from '@features/content/exercises';

import FillGapExerciseInterface from './interfaces/FillGapExerciseInterface';
import { FillGapExerciseActionsCreator } from '@actionCreators/FillGapExerciseActionsCreator';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;
const MAX_GROUPS = 3;

const FillGapExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedFillGapExerciseInterface>) => {
  const dispatch = useAppDispatch();
  const { isEditorHidden } = useIsEditorHidden({ bundleName: 'mainBundle', visitedBranch: 'phrase' });
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as FillGapExerciseInterface;

  let [totalKTags, setTotalKTags] = useState(
    KTagsCreatorHelpers.countKTags(
      ExerciseDataModelAssembler.findFieldValueInLanguageOfExercise(
        'phrase' as any,
        loadedExercise as any,
        courseLearningLanguage,
        'mainBundle',
      ),
    ),
  );

  let audioLocalizations = loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations;
  let learningLanguageAudioLocalization: LocalizationInterface | undefined;

  if (audioLocalizations !== undefined) {
    learningLanguageAudioLocalization = audioLocalizations.find(
      (localization: LocalizationInterface) => localization.language === courseLearningLanguage,
    );

    if (learningLanguageAudioLocalization !== undefined) {
      learningLanguageAudioLocalization = {
        ...learningLanguageAudioLocalization,
      };
    }
  }

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableAudioLocalizations = audioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  // Image and Video only have EN localization
  const imageLocalization = loadedExercise?.content?.mainBundle?.image?.imageLocalizations?.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );
  const videoLocalization = loadedExercise?.content?.mainBundle?.video?.videoLocalizations?.find(
    (videoLocalization) => videoLocalization.language === DEFAULT_LANGUAGE_V2,
  );

  useEffect(() => {
    if (totalKTags === 1 && loadedExercise?.content?.distractor1 === null) {
      dispatch(courseSlice.actions.fillDistractor1());
    } else if (totalKTags > 1 && loadedExercise?.content?.distractor2 === null) {
      dispatch(courseSlice.actions.fillDistractor2());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalKTags, dispatch]);

  const { isEditAvailable } = useIsEditAvailable();

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle');
  const phraseErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      (error.field === 'mainBundle.phrase' && !error.message.toLowerCase().includes('audio')) ||
      error.field === 'phrase',
  );
  const phraseAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' && error.message.toLowerCase().includes('audio'),
  );

  if (phraseAudioErrors.length) {
    let updatedErrors = [...errors];

    availableAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const mainImageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.image');

  if (mainImageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const mainVideoErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.video');

  if (mainVideoErrors.length && videoLocalization?.processed && videoLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.VIDEO.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const distractorsErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'distractors' || error.field === 'distractors.phrase',
  );
  const distractor1Errors = errors.filter((error: ValidationErrorInterface) => error.field === 'distractor1.phrase');
  const distractor2Errors = errors.filter((error: ValidationErrorInterface) => error.field === 'distractor2.phrase');

  const isPhraseChangeBlocked =
    (loadedExercise.content?.mainBundle?.phrase?.mappings?.count > 1 ||
      loadedExercise.content?.mainBundle?.phrase?.mappings?.length > 1) &&
    !loadedExercise.content?.mainBundle?.phrase?.isReusingConfirmed;

  const isMainBundleChangeBlocked =
    loadedExercise.content.mainBundle?.mappings?.count &&
    loadedExercise.content.mainBundle?.mappings?.count > 1 &&
    !loadedExercise.content.mainBundle?.isReusingConfirmed;

  const requestOrCancelAudioPayload = {
    bundleName: 'mainBundle',
    field: 'phrase',
    language: courseLearningLanguage,
    type: ContentTypes.exercise,
  };

  const setReusedData = (id: string, field: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        'mainBundle',
        isBundle,
      ),
    );
  };

  const nullifyDistractor = (field: string) => {
    dispatch(FillGapExerciseActionsCreator.nullifyDistractor(field));
    const fieldName = `${field}_phrase` as keyof FormikValuesInterface;
    const prevValues = values[fieldName];
    if (Array.isArray(prevValues)) {
      setFieldValue(
        `${field}_phrase`,
        prevValues?.map((item: FormikValueInterface | string) => ({ ...(item as FormikValueInterface), value: '' })),
      );
    }
  };

  const renderEditor = () => (
    <TranslationsTipV2
      bundleName={'mainBundle'}
      visitedBranch={'phrase'}
      showLanguageSwitcher={false}
      errors={phraseErrors}
      showErrorDisplayer={false}
      flipped={false}
    >
      <FillgapTool
        placeholder={`Start writing in ${LANGUAGE_NAMES_V2[courseLearningLanguage]}`}
        gapMode
        maxGroups={MAX_GROUPS}
        onChangeGroups={(totalGroups: number) => {
          if (totalKTags !== 0 && totalGroups === 0) {
            nullifyDistractor('distractor1');
            nullifyDistractor('distractor2');
          } else if (totalKTags === 1 && totalGroups === 0) {
            nullifyDistractor('distractor1');
          } else if (totalKTags > 1 && totalGroups === 1) {
            nullifyDistractor('distractor2');
          }
          setTotalKTags(totalGroups);
        }}
        errors={phraseErrors}
      />
    </TranslationsTipV2>
  );

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.exercise,
          field: 'phrase',
          language: courseLearningLanguage,
          bundleName: 'mainBundle',
        }),
      );
    },
    [dispatch, courseLearningLanguage],
  );

  return (
    <div className="exercise-fill-gap">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Fillgap</Title>
        <HelpDisplayer type="guideline" id="guideline-fillgap-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-fill-gap__main-wrapper">
        <div className="exercise-fill-gap__wrapper">
          <div className="exercise-fill-gap__container">
            <h1 className="exercise-fill-gap__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(FillGapExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </div>

        <ResourceBundleWrapper
          bundle={loadedExercise.content.mainBundle}
          bundleName={'mainBundle'}
          errors={mainBundleErrors}
          resources={getResources(loadedExercise, courseLearningLanguage, 'mainBundle')}
        >
          <>
            <div className="exercise-fill-gap__wrapper">
              <div className="exercise-fill-gap__container">
                <h1 className="exercise-fill-gap__box-title">Phrase</h1>
                <ValidationErrorDisplayer
                  type={phraseErrors.some((e) => !e.isWarning) ? 'error' : 'warning'}
                  text={helpersService.getValidationErrorMessageText(phraseErrors)}
                />
              </div>
              {isEditorHidden ? <div>{renderEditor()}</div> : <>{renderEditor()}</>}
            </div>
            <div className="exercise-fill-gap__wrapper">
              <ContentToggler
                text="Image"
                open={loadedExercise.content.mainImageEnabled}
                onSwitch={() => {
                  dispatch(ExerciseCommonActions.setImageEnabled(!loadedExercise.content.mainImageEnabled));
                }}
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true);
                  } else {
                    dispatch(FillGapExerciseActionsCreator.removeImage());
                  }
                }}
                isChangeBlocked={!!isMainBundleChangeBlocked}
                errors={mainImageErrors}
              >
                <ImageUpload
                  width={ImageUploadDimensionDescriptors.fillgap.image.width}
                  height={ImageUploadDimensionDescriptors.fillgap.image.height}
                  size={Sizes.fullscreen}
                  mode={ImageUploadModes.normal}
                  onChange={(file: File, progressHandler: Function) => {
                    if (file !== null && loadedExercise.content.id !== undefined) {
                      dispatch(FillGapExerciseActionsCreator.setImage(loadedExercise, file, progressHandler));
                    }
                  }}
                  onRemove={() => {
                    dispatch(FillGapExerciseActionsCreator.removeImage());
                  }}
                  imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                    loadedExercise,
                    'image',
                    undefined,
                    'mainBundle',
                  )}
                  onProcessingFinished={(url: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setValueAfterProcessing({
                        url,
                        mediaType: 'image',
                        type: ContentTypes.exercise,
                        field: 'image',
                        language: DEFAULT_LANGUAGE_V2,
                        bundleName: 'mainBundle',
                      }),
                    );
                  }}
                  previewMode={false}
                  isForExercise
                  errors={mainImageErrors}
                  isChangeBlocked={!!isMainBundleChangeBlocked}
                  onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true)}
                />
              </ContentToggler>
            </div>
            <div className="exercise-fill-gap__wrapper">
              <ContentToggler
                text="Audio"
                open={loadedExercise.content.mainAudioEnabled}
                onSwitch={() => {
                  dispatch(ExerciseCommonActions.setAudioEnabled(!loadedExercise.content.mainAudioEnabled));
                }}
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
                  } else if (isPhraseChangeBlocked) {
                    setReusedData(
                      loadedExercise.content.mainBundle?.phrase?._id ||
                        loadedExercise.content.mainBundle?.phrase?.id ||
                        '',
                      'phrase',
                      false,
                    );
                  } else {
                    dispatch(
                      FillGapExerciseActionsCreator.removeAudio({
                        contentId:
                          loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id,
                        language: courseLearningLanguage,
                      }),
                    );
                  }
                }}
                isChangeBlocked={isMainBundleChangeBlocked || isPhraseChangeBlocked}
                errors={phraseAudioErrors}
              >
                <AudioUpload
                  audioData={ImageUploadUtils.getAudioForFileUpload(
                    loadedExercise,
                    'phrase',
                    courseLearningLanguage,
                    'mainBundle',
                  )}
                  onAudioRequestSuccess={(audioRequestId: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                        ...requestOrCancelAudioPayload,
                        audioRequestData: {
                          id: audioRequestId,
                          status: 'new',
                        },
                      }),
                    );
                  }}
                  onCancelAudioRequestSuccess={() => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
                    );
                  }}
                  onProcessingFinished={onProcessingFinished}
                  onChange={(uploadedSound, progressHandler) => {
                    dispatch(
                      AudioUploadActionsCreator.uploadSound(
                        loadedExercise.content.id,
                        ExerciseTypes.fillgap,
                        courseLearningLanguage,
                        loadedExercise.content?.mainBundle?.phrase?._id ||
                          loadedExercise.content?.mainBundle?.phrase?.id,
                        learningLanguageAudioLocalization,
                        uploadedSound,
                        'phrase',
                        undefined,
                        undefined,
                        true,
                        'mainBundle',
                        progressHandler,
                      ),
                    );
                  }}
                  onChangeInstant={() => {
                    if (isMainBundleChangeBlocked) {
                      setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
                    } else {
                      setReusedData(
                        loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id ||
                          '',
                        'phrase',
                        false,
                      );
                    }
                  }}
                  isChangeBlocked={isMainBundleChangeBlocked || isPhraseChangeBlocked}
                  onRemove={() => {
                    dispatch(
                      FillGapExerciseActionsCreator.removeAudio({
                        contentId:
                          loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id,
                        language: courseLearningLanguage,
                      }),
                    );
                  }}
                  fullScreen
                  currentLanguage={courseLearningLanguage}
                  fieldName={'phrase'}
                  errors={phraseAudioErrors}
                />
              </ContentToggler>
            </div>

            <ContentToggler
              text="Video"
              open={!!loadedExercise?.content?.mainVideoEnabled}
              onRemove={() => {
                dispatch(FillGapExerciseActionsCreator.removeVideo());
                dispatch(ExerciseCommonActions.setVideoEnabled(false));
              }}
              onSwitch={(opened) => {
                if (opened) {
                  dispatch(ExerciseCommonActions.setVideoEnabled(true));
                }
              }}
              errors={mainVideoErrors}
            >
              <VideoUpload
                videoData={ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')}
                onChange={(uploadedVideo, progressHandler) => {
                  dispatch(
                    VideoUploadActionsCreator.uploadVideo(loadedExercise, uploadedVideo, 'mainBundle', progressHandler),
                  );
                }}
                onProcessingFinished={(url) => {
                  dispatch(
                    CommonExerciseActionsCreator.setValueAfterProcessing({
                      url,
                      mediaType: 'video',
                      type: ContentTypes.exercise,
                      field: 'video',
                      language: DEFAULT_LANGUAGE_V2,
                      bundleName: 'mainBundle',
                    }),
                  );
                }}
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'video', true);
                  } else {
                    dispatch(FillGapExerciseActionsCreator.removeVideo());
                  }
                }}
                isChangeBlocked={!!isMainBundleChangeBlocked}
                fullScreen
                errors={mainVideoErrors}
                fieldName="video"
                onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'video', true)}
              />
            </ContentToggler>
          </>
        </ResourceBundleWrapper>

        {totalKTags > 0 && (
          <div className="exercise-fill-gap__wrapper">
            <div className="exercise-fill-gap__container">
              <h1 className="exercise-fill-gap__box-title">Distractors</h1>
              <ValidationErrorDisplayer
                text={helpersService.getValidationErrorMessageText([
                  ...distractorsErrors,
                  ...distractor1Errors,
                  ...distractor2Errors,
                ])}
              />
            </div>

            <ul className="exercise-fill-gap__distractors">
              {totalKTags >= 1 && loadedExercise?.content?.distractor1 && (
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.distractor1}
                  bundleName={'distractor1'}
                  withoutVocabularyBox
                  withRedBackground
                  resources={getResources(loadedExercise, courseLearningLanguage, 'distractor1')}
                >
                  <>
                    <div className="exercise-fill-gap__container">
                      <h1 className="exercise-fill-gap__box-title">Distractor 1</h1>
                      <ValidationErrorDisplayer
                        type={distractor1Errors.some((e) => !e.isWarning) ? 'error' : 'warning'}
                        text={helpersService.getValidationErrorMessageText(distractor1Errors)}
                      />
                    </div>
                    <li className="exercise-fill-gap__distractor">
                      <TranslationsTipV2
                        bundleName={'distractor1'}
                        visitedBranch={'phrase'}
                        errors={distractor1Errors}
                        showErrorDisplayer={false}
                        flipped={false}
                      >
                        <WritableInputText id="fill-gap-distractor1-input" />
                      </TranslationsTipV2>
                    </li>
                  </>
                </ResourceBundleWrapper>
              )}

              {totalKTags >= 2 && loadedExercise?.content?.distractor2 && (
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.distractor2}
                  bundleName={'distractor2'}
                  withoutVocabularyBox
                  withRedBackground
                  resources={getResources(loadedExercise, courseLearningLanguage, 'distractor2')}
                >
                  <>
                    <div className="exercise-fill-gap__container">
                      <h1 className="exercise-fill-gap__box-title">Distractor 2</h1>
                      <ValidationErrorDisplayer
                        type={distractor2Errors.some((e) => !e.isWarning) ? 'error' : 'warning'}
                        text={helpersService.getValidationErrorMessageText(distractor2Errors)}
                      />
                    </div>
                    <li className="exercise-fill-gap__distractor">
                      <TranslationsTipV2
                        bundleName={'distractor2'}
                        visitedBranch={'phrase'}
                        errors={distractor2Errors}
                        showErrorDisplayer={false}
                        flipped={false}
                      >
                        <WritableInputText id="fill-gap-distractor2-input" />
                      </TranslationsTipV2>
                    </li>
                  </>
                </ResourceBundleWrapper>
              )}
            </ul>
          </div>
        )}

        <div className="exercise-fill-gap__wrapper">
          <Explanation />
        </div>
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </div>
    </div>
  );
};

export default FillGapExercise;
