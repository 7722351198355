import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { LoadingStage } from '@common/enums/LoadingStage';
import { Loader } from '@features/theme';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCoursesInfo } from '@selectors/CoursesSelectors';
import { selectSearchPanel } from '@selectors/UiSelectors';

import type { AvailableSearchType } from '../../types';
import { useSearch } from '../SearchContext';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { SearchContent } from './SearchContent';
import {
  type FavouriteSearch,
  getFavouriteSearches,
  removeFavouriteSearch,
  updateFavouriteSearch,
} from './SearchContent/Common/favouriteSearches';
import { SearchTypeSelector } from './SearchTypeSelector';
import { DBId } from '@common/types/DBId';

const SearchModal = styled(Modal)<{ $searchType: AvailableSearchType }>`
  padding: 0 4rem !important;

  .modal-content {
    border: none;
    border-radius: 1.6rem;
    box-shadow: 0 0.8rem 1.2rem rgba(0, 0, 0, 0.1);
    height: ${({ $searchType }) => ($searchType ? '90vh' : '67vh')};
    transition: height 0.3s ease-out;
    width: 100%;
  }

  .modal-header {
    border: none;
    display: flex;
    padding: 0;
    position: relative;

    .close {
      color: ${({ theme }) => theme.color.brandBlack};
      font-size: 4rem;
      font-weight: normal;
      margin: 0;
      opacity: 1;
      padding: 0;
      position: absolute;
      right: 4.4rem;
      top: 2.4rem;
      z-index: 100;
    }
  }

  .modal-dialog {
    max-width: 132.8rem;
  }

  .modal-body {
    border-radius: 1.6rem;
    background-color: ${({ theme }) => theme.color.brandWhite};
    overflow: ${({ $searchType }) => ($searchType ? 'hidden' : 'visible')};
    padding: 4rem;
  }
`;

export const SearchContainer = () => {
  const dispatch = useAppDispatch();
  const { searchType, setQuery, setSearchType, setSearchLoading, setSearchLoaded } = useSearch();

  // Courses info is needed for a course list in one of the filters, but I'm not sure we need it in this component
  const coursesInfo = useAppSelector(selectCoursesInfo);
  const { predefinedType, opened } = useAppSelector(selectSearchPanel);

  // Because search type is stored in context, we need to update it when it changes in Redux
  useEffect(() => {
    setSearchType(predefinedType || null);
  }, [setSearchType, predefinedType]);

  // @TODO Improve how favourite searches are loaded to avoid layout shifts
  const [favouriteSearches, setFavouriteSearches] = useState<FavouriteSearch<any>[]>([]);
  useEffect(() => {
    if (searchType === null) {
      getFavouriteSearches<any>().then((favouriteSearches) => setFavouriteSearches(favouriteSearches));
    }
  }, [searchType]);

  const removeSearchFromFavourites = (favouriteSearchId: DBId) => {
    removeFavouriteSearch(favouriteSearchId).then((favouriteSearches) => setFavouriteSearches(favouriteSearches));
  };

  const updateSearchInFavourites = (favouriteSearchId: DBId, name: string) => {
    updateFavouriteSearch(favouriteSearchId, name).then((favouriteSearches) => setFavouriteSearches(favouriteSearches));
  };

  const onCloseSearch = () => {
    setQuery(null);
    setSearchType(null);
    dispatch(SearchModalActionsCreator.hideSearchV2());
  };

  // Remove back button if search type is predefined
  const onBack = predefinedType
    ? null
    : () => {
        setSearchType(null);
        setSearchLoading(false);
        setSearchLoaded(false);
        dispatch(SearchModalActionsCreator.resetFilters());
      };

  return (
    <SearchModal $searchType={searchType} show={opened} onHide={onCloseSearch} centered>
      {coursesInfo.loaded !== LoadingStage.loaded ? (
        <Loader size="L" />
      ) : searchType ? (
        <SearchContent searchType={searchType} onBack={onBack} />
      ) : (
        <>
          <Modal.Header closeButton />
          <Modal.Body>
            <SearchTypeSelector
              selectSearchType={setSearchType}
              favouriteSearches={favouriteSearches}
              selectFavouriteSearch={(favouriteSearch: FavouriteSearch<any>) => {
                // @TODO Find a better way to pass initialFilters to search component. NB they are not predefinedFilters
                dispatch(
                  SearchModalActionsCreator.showSearchV2Panel({
                    filtersPreset: {
                      [favouriteSearch.type]: favouriteSearch.filters,
                    },
                  }),
                );
                setSearchType(favouriteSearch.type);
              }}
              removeFavouriteSearch={removeSearchFromFavourites}
              updateFavouriteSearch={updateSearchInFavourites}
            />
          </Modal.Body>
        </>
      )}
    </SearchModal>
  );
};
