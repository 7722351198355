import styled from 'styled-components/macro';

import { HeaderWrapper } from './HeaderWrapper';

const LexicalItemHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: calc(5.6rem + 50%) minmax(8.5rem, 1fr) minmax(8.5rem, 1fr) 5%;
`;

export const LexicalItemSearchResultTypeHeader = () => (
  <LexicalItemHeaderWrapper>
    <span>Phrase</span>
    <span>Translations</span>
    <span>Audio</span>
  </LexicalItemHeaderWrapper>
);
