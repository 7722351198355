import { FunctionComponent } from 'react';
import styled from 'styled-components';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import helpersService from '@services/HelpersService';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ResourceBundleWrapper, ResourceData } from '@components/Exercises/Common/ResourceBundle';
import { PreviewModal } from '@features/content/preview';
import { Explanation, TitleContainer, Title } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import { MediaDataInterface } from '@common/interfaces/contentTypes/MediaDataInterface';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { AudioUpload } from '@components/MediaUpload';
import KTagsCreator from '@components/Exercises/Common/KTagsCreator/KTagsCreator';
import KTagSelectionStrategy from '@components/Exercises/Common/KTagsCreator/strategies/KTagSelectionStrategy';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExperimentSelector } from '@features/experiment';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { AccessWarning } from '@components/Warning';
import type { LanguageV2 } from '@features/content/languages';
import PhraseBuilderExerciseContent from '@components/Exercises/PhraseBuilder/interfaces/PhraseBuilderExerciseContent';
import { PhraseBuilderMediaVariants } from './PhraseBuilderMediaVariants';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useAppDispatch } from '@redux/store';
import { Instructions } from '@features/content/exercises';

const WrapperWithMarginBottom = styled.div`
  margin-bottom: 32px;
`;

const FieldTitle = styled.h1`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 14px;
  margin-bottom: 8px;
`;

interface PhraseBuilderExerciseViewInterface {
  isEditAvailable: boolean;
  uploadSound: (uploadedSound: File, progressHandler: (progress: number) => void) => void;
  isChangeBlocked: boolean;
  isAudioTogglerOpened: boolean;
  audioData: MediaDataInterface | undefined;
  onAudioTogglerRemove: () => void;
  onInstructionLanguageChange: (displayContentToUserMode: DisplayContentToUserModeType) => void;
  onAudioRemove: () => void;
  onAudioChangeInstant: () => void;
  onAudioProcessingFinish: (url: string) => void;
  instructionsErrors: ValidationErrorInterface[];
  mainBundleErrors: ValidationErrorInterface[];
  phraseErrors: ValidationErrorInterface[];
  phraseAudioErrors: ValidationErrorInterface[];
  isEditorHidden: boolean;
  bundleResources: ResourceData[];
  exerciseContent: PhraseBuilderExerciseContent;
  courseLearningLanguage: LanguageV2;
  onMediaTypeChange: (newMediaType: boolean) => void;
}

export const PhraseBuilderExerciseView: FunctionComponent<PhraseBuilderExerciseViewInterface> = ({
  isEditAvailable,
  uploadSound,
  isChangeBlocked,
  isAudioTogglerOpened,
  audioData,
  onAudioTogglerRemove,
  onInstructionLanguageChange,
  onAudioRemove,
  onAudioProcessingFinish,
  instructionsErrors,
  mainBundleErrors,
  phraseErrors,
  phraseAudioErrors,
  isEditorHidden,
  bundleResources,
  onAudioChangeInstant,
  exerciseContent,
  courseLearningLanguage,
  onMediaTypeChange,
}: PhraseBuilderExerciseViewInterface) => {
  const dispatch = useAppDispatch();
  const requestOrCancelAudioPayload = {
    bundleName: 'mainBundle',
    field: 'phrase',
    language: courseLearningLanguage,
    type: ContentTypes.exercise,
  };

  const renderEditor = () => (
    <TranslationsTipV2
      bundleName={'mainBundle'}
      visitedBranch={'phrase'}
      errors={phraseErrors}
      showErrorDisplayer={false}
      showLanguageSwitcher={false}
      flipped={false}
    >
      <KTagsCreator addGroupStrategy={KTagSelectionStrategy} />
    </TranslationsTipV2>
  );

  return (
    <>
      <PreviewModal type={ExerciseTypes.phraseBuilder} content={exerciseContent} />
      <TitleContainer>
        <Title>Phrase Builder</Title>
        <HelpDisplayer type="guideline" id="guideline-phrase-builder-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={exerciseContent.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <WrapperWithMarginBottom>
        <FieldTitle>Instruction</FieldTitle>
        <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
        <Instructions />
        <UserLanguageCheckbox
          checkedField={exerciseContent.instructionsLanguage}
          onChange={(displayContentToUserMode: DisplayContentToUserModeType) =>
            onInstructionLanguageChange(displayContentToUserMode)
          }
        />
      </WrapperWithMarginBottom>

      <PhraseBuilderMediaVariants isAudioEnabled={exerciseContent.displayAudio} onMediaTypeChange={onMediaTypeChange} />

      <ResourceBundleWrapper
        bundle={exerciseContent.mainBundle}
        bundleName={'mainBundle'}
        errors={mainBundleErrors}
        withoutVocabularyBox
        resources={bundleResources}
      >
        <WrapperWithMarginBottom>
          <ContentToggler
            noMargin
            text="Audio"
            open={isAudioTogglerOpened}
            onRemove={onAudioTogglerRemove}
            isChangeBlocked={isChangeBlocked}
            errors={phraseAudioErrors}
          >
            <AudioUpload
              audioData={audioData}
              onAudioRequestSuccess={(audioRequestId: string) => {
                dispatch(
                  CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                    ...requestOrCancelAudioPayload,
                    audioRequestData: {
                      id: audioRequestId,
                      status: 'new',
                    },
                  }),
                );
              }}
              onCancelAudioRequestSuccess={() => {
                dispatch(
                  CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
                );
              }}
              onProcessingFinished={(url: string) => onAudioProcessingFinish(url)}
              onChange={(uploadedSound, progressHandler) => uploadSound(uploadedSound, progressHandler)}
              onChangeInstant={onAudioChangeInstant}
              isChangeBlocked={isChangeBlocked}
              onRemove={onAudioRemove}
              fullScreen
              currentLanguage={courseLearningLanguage}
              fieldName={'phrase'}
              errors={phraseAudioErrors}
            />
          </ContentToggler>
        </WrapperWithMarginBottom>
        <WrapperWithMarginBottom>
          <FieldTitle>Phrase</FieldTitle>
          <ValidationErrorDisplayer
            text={helpersService.getValidationErrorMessageText(phraseErrors)}
            type={phraseErrors.some((e) => !e.isWarning) ? 'error' : 'warning'}
          />
          {isEditorHidden ? <div>{renderEditor()}</div> : <>{renderEditor()}</>}
        </WrapperWithMarginBottom>
      </ResourceBundleWrapper>

      <Explanation />
      <RecapSelector exerciseId={exerciseContent.id} />
    </>
  );
};
