import React, { FocusEvent, useState, ReactElement } from 'react';

import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import type { ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { TranslationsTipWrapper } from '@components/TranslationTip/styles';
import { useToast } from '@features/app/toast';
import { useIsAdmin } from '@features/auth/roles';
import type { LanguageV2 } from '@features/content/languages';
import { useAppDispatch } from '@redux/store';

import { TranslationsTipWidget } from './TranslationsTipWidget';
import { TranslationsTipFieldNameForRoleplay } from './types';

type TranslationsTipProps = {
  children: ReactElement;
  translationElements: string;
  audioElements: string;
  visitedContentId: DBId | undefined;
  fieldName: TranslationsTipFieldNameForRoleplay;
  isEditDisabled: boolean;
  isChangeBlocked: boolean;
  onLanguageSwitchToggled: (switchedToEnglishLanguage: boolean, isPhoneticSelected: boolean) => void;
  type: ContentTypesType;
  currentLanguage: LanguageV2;
  className?: string;
  suggestionsQuery: string;
  compact?: boolean;
  defaultContextForTranslators?: string;
};

export const TranslationsTip = ({
  children,
  translationElements,
  audioElements,
  visitedContentId,
  fieldName,
  isEditDisabled,
  isChangeBlocked,
  onLanguageSwitchToggled,
  type,
  currentLanguage,
  className,
  suggestionsQuery,
  compact = false,
  defaultContextForTranslators,
}: TranslationsTipProps) => {
  const dispatch = useAppDispatch();
  const [isWidgetActive, setIsWidgetActive] = useState(false);
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);

  const isAdmin = useIsAdmin();

  const showToast = useToast();

  const showWidget = () => {
    setIsWidgetActive(true);
    setIsWidgetVisible(true);
  };

  const hideWidget = () => {
    setIsWidgetActive(false);
    setTimeout(() => {
      setIsWidgetVisible(false);
    }, 500);
  };

  const showDefaultStringWarning = () => {
    showToast({
      type: 'info',
      title: 'Default instructions cannot be edited',
      description: 'To remove the default instruction, clear it using the trash can',
    });
  };

  return (
    <TranslationsTipWrapper className={className}>
      <div
        onBlur={(e: FocusEvent<HTMLDivElement>) => hideWidget()}
        onFocus={(e: FocusEvent<HTMLDivElement>) => {
          showWidget();
        }}
      >
        {React.cloneElement(children, {
          ...children.props,
          onChangeInstant: () => {
            if (visitedContentId?.includes('default_') && !isAdmin) {
              showDefaultStringWarning();
            } else {
              if (isChangeBlocked) {
                dispatch(TranslationTipActionsCreator.setCurrentContentId(visitedContentId || '', type, fieldName));
              }
            }
          },
          isChangeBlocked,
        })}
      </div>
      <TranslationsTipWidget
        translationElements={translationElements}
        audioElements={audioElements}
        isWidgetActive={isWidgetActive}
        isWidgetVisible={isWidgetVisible}
        visitedContentId={visitedContentId}
        fieldName={fieldName}
        isEditDisabled={isEditDisabled}
        isChangeBlocked={isChangeBlocked}
        onLanguageSwitchToggled={onLanguageSwitchToggled}
        type={type}
        currentLanguage={currentLanguage}
        suggestionsQuery={suggestionsQuery}
        compact={compact}
        defaultContextForTranslators={defaultContextForTranslators}
      />
    </TranslationsTipWrapper>
  );
};
