import { LanguageV2 } from '@features/content/languages';
import { getPhrasesToDisplay, VocabularyReviewListItem } from '@features/content/vocabularyReview';

import { CardTitle } from '../Common';
import { CardImage } from './CardImage';
import { NavigateLink } from './NavigateLink';
import { LexicalItemCardWrapper, StyledCardAudio, StyledCardTranslation } from './styles';

type LexicalItemResultCardProps = {
  content: VocabularyReviewListItem;
  language: LanguageV2;
};

export const LexicalItemResultCard = ({ content, language }: LexicalItemResultCardProps) => {
  const { id, image, hasAudio, phrase, translationsCount } = content;
  const title = getPhrasesToDisplay(phrase, language);
  const path = `/vocabulary-review/${language}/lexical-item/${id}`;

  return (
    <LexicalItemCardWrapper isExpanded={false}>
      <CardImage image={image} />
      <CardTitle id={id} title={title} />
      <StyledCardTranslation translations={translationsCount} type="text"></StyledCardTranslation>
      <StyledCardAudio hasAudio={hasAudio}></StyledCardAudio>
      <NavigateLink path={path} />
    </LexicalItemCardWrapper>
  );
};
